<template>
  <div>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div class="toolBox">
        <van-button @click="search()" type="primary">搜索</van-button>
      </div>
      <div class="tableBox">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>deviceId</th>
              <th>deeper chain wallet</th>
              <th>createdAt</th>
              <th>updatedAt</th>
              <th>

              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataList" :key="item._id">
              <td>{{index + 1}}</td>
              <td>{{item.deviceId}}</td>
              <td>{{item.publicKey}}</td>
              <td>{{$moment(item.createdAt).format()}}</td>
              <td>{{$moment(item.updatedAt).format()}}</td>
              <td>
                <van-button @click="unbind(item)" type="danger">解绑</van-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from "../NavBox.vue";
export default {
  data() {
    return {
      dataList: [],
      searchVal: ''
    }
  },
  components: {
    Navbar,
    NavBox,
  },
  methods: {
    search() {
      let searchVal = prompt("搜索")
      if (!searchVal) {
        searchVal = this.searchVal
      }
      this.getData(searchVal)
    },
    getData(searchVal) {
      return this.$fetch({
        url: '/admin/bindingWallet',
        headers: {
          Authorization: `${window.localStorage.getItem("adminToken")}`,
        },
        params: {
        search: searchVal
      }
      }).then(res => {
        this.dataList = res.data;
        this.$toast(`共搜索：${res.data.length} 条数据`)
      })
    },
    unbind(item) {
      if (confirm(`确定要解除 \n ${item.publicKey} \n 和 \n ${item.deviceId} \n 的关系吗 ？`)) {
        this.$fetch({
          url: '/admin/bindingWallet',
          method: 'DELETE',
          headers: {
            Authorization: `${window.localStorage.getItem("adminToken")}`,
          },
          data: {
            id: item._id
          }
        }).then(res => {
          this.getData(this.searchVal)
        })
      }
    }
  }
};
</script>

<style lang="less" scoped>
.toolBox {
  display: flex;
  padding: 10px 30px;
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,
    td {
      padding: 5px 2px;
    }
    tbody {
      tr{
        &:hover {
          background: #eee;
        }
      }
    }
  }
}
</style>